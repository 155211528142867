import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';
import "./css/main.min.css";
import "./css/page.min.css";
import "./css/scoller.min.css";
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { pushRotate as Menu } from 'react-burger-menu';
import { LandingPage } from './components/Landingpage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Contact } from './components/Contact';
import 'rsuite/dist/rsuite.min.css';
import { Footer } from './components/Footer';
import { Maintenance } from './components/Maintenance';

function App() {

  const [domain, setDomain] = useState('');
  const [maintenance, setMaintenance] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Outfit']
      }
    });

    const hostname = window.location.hostname;
    setDomain(hostname.endsWith('.nl') ? 'nl' : 'com');

    const button = document.getElementById("toggleMenu");
    if (button) {
      button.addEventListener('click', toggleMenu);
    }
  
    // Cleanup event listener
    return () => {
      if (button) {
        button.removeEventListener('click', toggleMenu);
      }
    };

  }, []);

  return (
    <>
      <Helmet>
        {domain === 'nl'
          ? [
              <title key="title">Online zichtbaar worden met ZWL Software | Expert in Webdesign</title>,
              <meta
                key="description"
                name="description"
                content="Op zoek naar de perfecte website en wil je de online zichtbaarheid van je bedrijf verbeteren? Bij ZWL Software, jouw expert in webdesign, maken we op maat gemaakte websites die passen bij jouw bedrijf. "
              />,
            ]
          : [
            <title>Boost Your Online Presence with ZWL Software | Expert in Web Design</title>,
            <meta name="description" content="Looking for the perfect website while boosting your business's online visibility? At ZWL Software, your expert in web design, we create custom websites that fit your business. " />,
            
            ]}
      </Helmet>

      {maintenance === false ? (
        <main id="page-wrap">
          <Header />
                <Router>
                  <Routes>
                    <Route path='/' element={<LandingPage/>} ></Route>
                    <Route path='/contact' element={<Contact />} ></Route>
                  </Routes>
                </Router>
              <Footer />
        </main>
      ) : <Maintenance /> }

      {/* Rest of your application */}
    </>
  );
}

export default App;
