import { FaArrowRight } from "react-icons/fa";
import { Button, Input, SelectPicker } from "rsuite";

export const Contact = () => {

    const data = [ 'SEO Serivce', 'Website laten maken', 'Webdesign', 'Applicatie design',  'Hosting', 'Domein',  'Overige software ontwikkeling', 'Overig'].map(
        item => ({ label: item, value: item })
      );

    return(
        <div>
            <div className="my-20 flex justify-center items-center   "> 
                <div className=" w-1/2 form  ">
                    <h5 class="title-default text-center " > Vragen & contact:</h5>
                    <p className="text-center " > Wij nemen zo snel mogelijk contact met je op.</p>

                    <form>

                        <p> Ik heb interesse in.. </p>
                        <SelectPicker


                            className="w-full selector"
                            data={data}
                            searchable={false}
                            placeholder="Kies een optie"
                            />

                            
                        <div className="md:flex mt-5 mb-4 gap-3 w-full">
                            <Input   type="text"
                            id="full-name"
                            name="full-name"
                            autoComplete="name"
                            className="input rounded w-full" placeholder="Naam" />
                            <Input name="Emailadres"  autoComplete="email" className="input rounded w-full" placeholder="Email" />

                        </div>
                        <div className="md:flex mt-5 mb-4 gap-3 w-full">
    {/* 
                        <Input 
                                className="input rounded w-full"
                                type="text"
                                id="company-name"
                                name="company-name"
                                autoComplete="organization"
                                placeholder="Bedrijfsnaam" /> */}

                        <textarea rows={5} maxLength={2089} name="Message"  className="input rounded w-full" placeholder="Bericht" />

                        </div>


                    </form>
                    {/* <p className="text-gray-400">  Binnnen 24 geen contact? Dan krijg je 5% korting op de eerst volgende offerte!</p> */}

                    <Button className="btn-main bg-secondary  text-white "><div className="bg-secondary flex items-center gap-1">Versturen <FaArrowRight/> </div> </Button>
                </div>
            </div>
       
        </div>
    );
}