import { TbDeviceDesktopCode } from "react-icons/tb";
import { Button, ButtonGroup } from "rsuite";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { FaCheck, FaLongArrowAltRight } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import PulsingSquares from "./PulseSquares";
import { FAQ } from "./FAQ";
import { Reviews } from "./Reviews";
import { Features } from "./Features";
export const LandingPage = () => {


    return (
        <>
            <div className="mt-20 mb-20 md:mt-40 md:mb-40 md:px-40 md:flex md:flex-nowrap flex-wrap  gap-10 justify-center items-center ">

                <div className=" md:w-1/2 ">
                    <div className="flex justify-start items-center ">
                        <h1 class="title md:min-h-[200px] text-black">
                            De software agency voor jouw website.

                        </h1>
                    </div>
                 
                    {/* <h5 className="my-6 title2 "  > Webdesign op maat voor jouw website, zodat jij online kunt schitteren. we luisteren naar jouw wensen, analyseren je doelgroep en creëren een unieke en website die bij jouw bedrijf past.  </h5> */}
                    <h5 className=" mb-6 title2 text-black-400"  > Online schitteren start bij ons.</h5>
                    <hr className="mt-2 mb-5">
                    </hr>
                    <div className="md:flex md:flex-nowrap flex-wrap items-center gap-4 mb-10 mr-10">
                        <span className="flex mt-1 items-center gap-2"> <FaCheck color="green" /> Grafisch design (Applicatie & Websites)  </span>
                        <span className="flex mt-1 items-center gap-2"> <FaCheck color="green" /> Maatwerk Web & Software ontwikkeling  </span>
                        <span className="flex mt-1 items-center gap-2"> <FaCheck color="green" /> Marketing (SEO, Website copywriting)</span>
                        <span className="flex mt-1 items-center gap-2 "> <FaCheck color="green" /> Hosting & Domeinen   </span>

                    </div>


                    <div className="flex justify-start items-center w-full">

                        <Button className="btn-main w-full md:w-1/3 btn mbtn50 btn-p rounded " >
                            <a className="w-full" href="/contact/">
                                <span className="w-full flex justify-center items-center gap-2 text-white hover:text-white" >
                                {/* Website laten maken */}
                                Neem contact op
                                     <FaArrowRightLong /> </span>
                            </a>
                        </Button>

                        <a className="ml-10" href="/website-laten-maken/">
                            <span className="w-full flex justify-center items-center gap-2 text-black hover:text-black" >
                            Website laten maken
                                    <FaArrowRightLong /> </span>
                        </a>
            </div>
                </div>
                <div className="  container md:flex items-center justify-center   hidden md:w-1/2 ">

                    <PulsingSquares />

                </div>
            </div>
            <div className=" md:px-40 px-10  my-20  w-full m-auto">
                <div className="md:flex-nowrap flex-wrap flex gap-10 md:gap-20 md:my-20">
                    <div className="feature w-full md:w-1/3">

                        <div className="flex md:flex-nowrap flex-wrap justify-start items-center md:gap-3  "> 
                            <span className="md:w-auto w-full icon flex justify-center md:justify-start items-center my-4 pack-iconsvg"> <AiOutlineFileSearch className="color-last" /> </span>
                            <div>
                                <h4 className="w-full text-black md:text-start text-center"> Marketing (SEO)</h4>
                                <p className="text-start">   Jouw website is het visitekaartje van je bedrijf. Daarbij is het belangrijk om goede relevante content te schrijven zodat jouw bezoekers weten waar het visitekaartje voor is. </p>
                                    
                            </div>
                        </div>

                    </div>
                   
                    <div className="feature w-full md:w-1/3">
 
                            <div className="flex md:flex-nowrap flex-wrap items-center md:gap-3"> 
                                <span className="md:w-auto w-full icon flex justify-center md:justify-start items-center my-4 pack-iconsvg "> <TbDeviceDesktopCode className="color-secondary" /> </span>
                                <div>
                                    <h4 className="w-full text-black md:text-start text-center md:mb-0 mb-3"> 
                                    Webdesign op Maat</h4>
                                    <p className="text-start">Elke onderneming is uniek, en dat geldt ook voor jouw website. Of je nu een website of een complex interactief platform op maat wil laten maken. wij zijn hier om jouw project tot leven te brengen.  </p>

                                </div>
                            </div>
                           
                   
                    </div>

                    <div className="feature w-full md:w-1/3">
                        
                        <div className="flex md:flex-nowrap flex-wrap items-center md:gap-3"> 
                        <span className="md:w-auto w-full icon flex justify-center md:justify-start items-center my-4 pack-iconsvg"> <AiOutlineFileSearch className="color-primary" /> </span>

                            <div>
                            <h4 className="w-full text-black md:text-start text-center"> Hosting & Domein</h4>
                            <p className="text-start">
                                Het is belangrijk om jouw website online te onderhouden. Dat is onze missie. Jouw website zorgenloos 24/7 online en daarbij ook GRATIS SSL Certificaat en razendsnelle beveiligde verbinding.
                            </p>
                            </div>
                        </div>


                        </div>
                     

                </div>
            </div>

            <div className="mt-20 mb-20 md:mt-40 md:mb-20 md:px-40 md:flex md:flex-nowrap flex-wrap  gap-10 justify-start items-center  ">
                <Features />

            </div>

            {/* --- */}
            {/* portfolio */}
            {/* --- */}

            <div className="mt-40 mb-60 w-full">
                <Reviews />
            </div>
            <div className="container m-auto mb-20 mt-20">
                <FAQ />
            </div>

            {/* --- */}
            {/* korte CTA titel */}
            {/* */}

            <div className="my-40">
                <h2 className="text-black text-center">Klaar om te starten? Laten we beginnen met  
                één klik! </h2>
                <div className="w-full flex justify-center my-10">
                    <Button className="bg-secondary cta-btn">Contact opnemen </Button>
                </div>
            </div>
            {/* --- */}
        </>
    );
}