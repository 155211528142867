import React from 'react';
import { FaStar } from 'react-icons/fa';

// Define a Review component
const Review = ({ text, profilePic, name, description, rating }) => {
    return (
        <div className="border border-gray-300 rounded-lg flex flex-col justify-between   md:w-1/5  w-full   text-start card1">
            <div className='px-10'>
                <div className='flex  mt-10  justify-center'>
                    <div className="  ">
                        {/* <img src={profilePic} alt={`${name}'s profile`} className="w-20 h-20 rounded-full object-cover  " /> */}
                        <h3 className="text-md mb-2 text-gray-900">{name}</h3>
                    </div>
                </div>
                {/* Rating stars */}

                <div className="flex justify-center mb-4 star">
                    {Array.from({ length: 5 }, (_, index) => (
                        <FaStar
                            key={index}
                            className={` text-yellow-500 ${index < rating ? 'fill-current' : 'text-gray-300'}`}
                        />
                    ))}
                </div>
                <div className='flex  justify-center'>
                    <blockquote className="text-xl text-center mb-4 text-gray-800">
                        "{text}"
                    </blockquote>
                </div>
            </div>
            <div className='flex'>
                    <div className='w-1/3 h-[20px] bg-last'></div>
                    <div className='w-1/3 h-[20px] bg-secondary'></div>
                    <div className='w-1/3 h-[20px] bg-primary'></div>
            </div>
        </div>
        
    );
};
// Styles for the component

// Example usage of the Review component
export const Reviews = () => {
    return (
        <div className='    '>
            <h4 className='text-center'>Een review van één van onze klanten:   </h4>
            {/* Ervaring van een van onze klanten: */}
            <div className='flex justify-center mt-5 gap-20 w-full items-center'>
                <div className="square bg-last text-center text-white flex justify-center items-center text-4xl">
                </div>

            <Review 
                text="Alles op tijd en op maat gemaakt. Fijne geduldig service, denkt goed mee en straalt enthousiasme uit."
                profilePic="https://via.placeholder.com/100" // Replace with actual profile picture URL
                name="Illias Az."
                description="Sweetspot"
                rating={5}
            />

                <div className="square bg-primary text-center text-white flex justify-center items-center text-4xl">
                </div>
            </div>
            
        </div>


    );
};
