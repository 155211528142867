import { Accordion, Button, Placeholder } from 'rsuite';

export const FAQ = () => {
    return(
        <>
        <h2>Heb je een vraag? </h2>
            <Accordion className='mt-5'>
                <Accordion.Panel header="Hoe kan ik contact opnemen?">
                    <Placeholder.Paragraph />
                </Accordion.Panel>

                <Accordion.Panel header="Maken jullie gebruik van wordpress?">
                    <Placeholder.Paragraph />
                </Accordion.Panel>

                <Accordion.Panel header="Bieden jullie ondersteuning en onderhoud aan na de lancering van de website?">
                    <Placeholder.Paragraph />
                </Accordion.Panel>

                <Accordion.Panel header="Heb ik een domein nodig om een website te laten maken? ">
                    <Placeholder.Paragraph />
                </Accordion.Panel>

                <Accordion.Panel header="Kan een website laten hosten als ik al een domein heb?">
                    <Placeholder.Paragraph />
                </Accordion.Panel>

                <Accordion.Panel header="Heb ik een website nodig?">
                    <Placeholder.Paragraph />
                </Accordion.Panel>

            </Accordion>
        </>
    );
}