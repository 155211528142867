import { Button } from "rsuite";

export const Features = () =>{
    return(
        <div className="flex flex-row-reverse items-start gap-10">
            <div className="w-1/2 page">
                <div className='img-container h-[90vh]'>
                    <img src='img/s1.jpg'  />  
                </div>
            </div>
            <div className="text-black w-1/2">

{/* Zonder gedoe jouw website online */}
                <h4 className="text-2xl">Jouw website zonder gedoe online 
                </h4>
                <h2 className="text-5xl">zo gaat dat.</h2>
                <div className="steps mt-10">
                    <div className="step">
                        <div className=" flex w-full justify-start items-center ">
                            <div className="  circle rounded bg-last" >1</div> <h2>Hallo? wie is daar.. </h2>
                        </div>
                        <div className=" flex w-full justify-start items-center ">
                            <div className=" block min-w-[40px] mr-3  " > </div>  
                            <p>De kennismaking, we breken het ijs en luisteren naar jouw wensen, analyseren je doelgroep.</p>
                        </div>
                    </div>
                     
                    <div className="step">
                        <div className=" flex w-full justify-start items-center ">
                            <div className="  circle rounded bg-secondary" >2</div> <h2>Designen maar! </h2>
                        </div>
                        <div className=" flex w-full justify-start items-center ">
                            <div className=" block min-w-[50px]  " > </div>  
                            <p>We gaan aan de slag! We maken een ontwerp en na jouw goedkeuring coderen we de website.</p>
                        </div>
                    </div>

                    <div className="step">
                        <div className=" flex w-full justify-start items-center ">
                            <div className="  circle rounded bg-primary" >3</div> <h2>Daar is die dan.  </h2>
                        </div>
                        <div className=" flex w-full justify-start items-center ">
                            <div className=" block min-w-[40px] mr-3  " > </div>  
                            <p>De website is af! Maar hoe kan iemand de website vinden? Dat regelen wij. Een razendsnelle beveiligde verbinding en ook nog eens een GRATIS SSL Certificaat!</p>
                        </div>
                    </div>
                </div>

              

            </div>
        </div>
    );
}

// een goede website is een investering niet een abbonement