// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import nlTranslations from './locales/nl/translation.json';

// Function to detect the language based on the domain
const detectLanguage = () => {
  const domain = window.location.hostname;

  if (domain.endsWith('.nl')) {
    return 'nl'; // Dutch for .nl domain
  } else {
    return 'en'; // Default to English for other domains
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      nl: {
        translation: nlTranslations,
      },
    },
    lng: detectLanguage(), // Set the detected language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
