
// Header.js
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'rsuite';

import { Turn as Hamburger } from 'hamburger-react'
import { useTranslation } from 'react-i18next';
import useScrollPosition from '../hooks/useScrollPosition';

export const Header = () => {
    const scrollPosition = useScrollPosition();
    const [isSticky, setIsSticky] = React.useState(false);

    
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const contentRef = useRef(null);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setHeight('0px');
        }
    }, [isOpen]);

 
  
    // Adjust the scroll threshold percentage here
    const threshold = 0.08 * window.innerHeight; // 25% of viewport height
  
    useEffect(() => {
      if (scrollPosition > threshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }, [scrollPosition, threshold]);
  

    
    return (
        <>
        <div class="bg">
            <div class="flex justify-end items-center gap-5 mr-10 h-full w-full"><span class="email mr-10 flex justify-start items-center gap-2">
                <span class="mailicon"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M460.6 147.3L353 256.9c-.8.8-.8 2 0 2.8l75.3 80.2c5.1 5.1 5.1 13.3 0 18.4-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8l-75-79.9c-.8-.8-2.1-.8-2.9 0L313.7 297c-15.3 15.5-35.6 24.1-57.4 24.2-22.1.1-43.1-9.2-58.6-24.9l-17.6-17.9c-.8-.8-2.1-.8-2.9 0l-75 79.9c-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8c-5.1-5.1-5.1-13.3 0-18.4l75.3-80.2c.7-.8.7-2 0-2.8L51.4 147.3c-1.3-1.3-3.4-.4-3.4 1.4V368c0 17.6 14.4 32 32 32h352c17.6 0 32-14.4 32-32V148.7c0-1.8-2.2-2.6-3.4-1.4z"></path><path d="M256 295.1c14.8 0 28.7-5.8 39.1-16.4L452 119c-5.5-4.4-12.3-7-19.8-7H79.9c-7.5 0-14.4 2.6-19.8 7L217 278.7c10.3 10.5 24.2 16.4 39 16.4z"></path></svg> </span>info@zwlsoftware.nl</span><span class="lang mr-10 flex justify-start items-center gap-2"><span class="icon"> <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path><path d="M3.6 9h16.8"></path><path d="M3.6 15h16.8"></path><path d="M11.5 3a17 17 0 0 0 0 18"></path><path d="M12.5 3a17 17 0 0 1 0 18"></path></svg> </span>
                    <select class="select-lang" aria-label="Selecteer een taal" id="select-lang" onchange="location = this.value;">
                        <option value="https://www.zwlsoftware.nl" id="nl">NL</option>
                        <option value="https://www.zwlsoftware.com" id="en">EN</option>
                    </select>
                </span></div>

        </div>
        {isSticky ? (
        <div className='w-full bg-white h-[100px] bg-gray-200'>
          {/* Content or styles for when sticky */}
        </div>
      ) : null}
        <header className={`header bg-white  ${isSticky ? 'sticky  shadow-md' : ''} `}>
          
            <div className={` nav-head h-full flex justify-center items-center` } >
                <div className=" min-w-[96%] container mx-auto flex items-center justify-between py-4 px-6">
                    {/* Logo */}
                    <div className="flex-shrink-0">
                        <div className="text-2xl font-bold logo"><a href='/'> <img src='/zwlBL.png' /></a> </div>

                    </div>

                    {/* Desktop Navigation Links */}
                    <nav className="hidden md:flex flex-grow justify-center space-x-6 ml-[-80px]">
                        <a href="/" className="text-black hover:text-black">Home</a>
                        <a href="/website-laten-maken" className="text-black hover:text-black">Website laten maken</a>
                        <a href="/diensten" className="text-black hover:text-black">Diensten</a>
                    </nav>

                    {/* Desktop Contact Button */}
                    <div className="hidden md:block">
                        <Button ripple={false} className='contact-btn-1 rounded px-9 py-2'> <a href="/contact" className="text-white hover:text-white w-full h-full text-white px-4 py-2   "> Contact  </a> </Button>
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="md:hidden flex items-center">
                        <button onClick={toggleMenu}   className="text-gray-800 focus:outline-none">
                            {/* <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
                            </svg> */}
                            <Hamburger direction='left' toggled={isOpen} />
                        </button>
                    </div>
                </div>

               
            </div>
             {/* Mobile Menu */}
       
             <div
            className={`mobile-menu md:hidden bg-white shadow-md overflow-hidden transition-all duration-300 ease-in-out`}
            style={{ height }}
        >
            <nav ref={contentRef} className="flex justify-start items-start flex-col px-6 py-4 space-y-2">
                <a href="/" className="text-black hover:text-black">{t('home')}</a>
                <a href="/website-laten-maken" className="text-black hover:text-black">Website laten maken</a>
                <a href="/diensten" className="text-black hover:text-black">Diensten</a>
                <Button  ripple={false} className='contact-btn-1 rounded ml-3 px-9 py-1   flex justify-center items-center'> <a href="/contact" className="text-nowrap   text-white  "> Contact opnemen  </a> </Button>

            </nav>
        </div>
          
        </header>
        </>
    );
};