export const Footer = () => {
    return(
        <>
            <div className="footer flex flex-col justify-end ">
                <div className="footer-middle">
                    <div className="flex">
                        <div className="w-1/4">
                            <h4> 
                                Onze gegevens
                            </h4>
                            <p>
                                ZWL Software
                                Udenseweg 2
                                Uden 5405 PA
                                Netherlands
                            </p>
                        </div>
                        <div className="w-1/4">test</div>
                        <div className="w-1/4">test</div>
                        <div className="w-1/4">test</div>
                    </div>
                </div>
            </div>
        </>
    );
}