import React from 'react';
import '../css/pulseart.min.css';
import Typewriter from 'typewriter-effect';
 
const PulsingSquares = () => {
 
  return (
    <div className="flex items-center">
      <div className="square left text-center text-white flex justify-center items-center text-4xl">
          {/* <div className='img-container'>
            <img src='img/071.jpg'  />  
          </div> */}
        </div>
      <div className="square center text-center text-white flex justify-center items-center text-4xl">
          <div className='img-container'>

          <img src='img/634.jpg'  />  
        </div>

      </div>
      <div className="square right text-center text-white flex justify-center items-center text-4xl">
          {/* <div className='img-container'>

              <img src='img/s1.jpg'  />  
          </div> */}

        </div>
    </div>
  );
};

export default PulsingSquares;
